:global {
	.submit-form {
		position: relative;
		flex: 1;
		width: 100%;
		align-items: center;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		&.hide {
			.form-inner,
			.form-footer {
				visibility: hidden;
			}
		}
		.form-inner {
			position: relative;
			flex: 1;
			opacity: 1;
			width: 278px;
			display: flex;
			align-items: center;
			flex-direction: column;
			justify-content: center;
		}
		.submit-btn {
			margin-top: 8px;
			display: flex;
			align-items: center;
			justify-content: center;
			width: 100%;
			height: 44px;
			background: linear-gradient(270deg, #ff7a36 0%, #ff2929 100%);
			border-radius: 22px;
			cursor: pointer;
			font-size: 16px;
			color: #fff;
			&.disable {
				background: #c7c9d3;
			}
		}
	}
	.form-control {
		position: relative;
		width: 100%;
		margin-bottom: 16px;
		.input {
			width: 100%;
			height: 44px;
			border-radius: 22px;
			border: 1px solid #eeeeee;
			box-sizing: border-box;
			padding: 0 16px;
			background: #fff;
			&.focused {
				border-color: #9a9da9;
			}
		}
		&.mobile {
			.area-select {
				position: absolute;
				height: 100%;
				width: 85px;
				display: flex;
				flex-direction: row;
				justify-content: space-between;
				align-items: center;
				padding: 0 8px 0 16px;
				cursor: pointer;
				span {
					flex: 1;
					color: #272e4d;
					font-size: 14px;
				}
				.i-arr {
					display: block;
					width: 14px;
					height: 14px;
					background-image: url(https://web-data.zmlearn.com/image/qomuyYnKQHjwZ7zAsXnxx/arr-down.png);
					background-size: cover;
					&.open {
						transform: rotate(180deg);
						transform-origin: center center;
					}
				}
				&::after {
					content: '';
					width: 1px;
					height: 16px;
					background: #eeeeee;
					position: absolute;
					top: 14px;
					right: 0;
				}
			}
			.input {
				padding-left: 94px;
			}
		}
		&.pwd {
			.input {
				padding-right: 45px;
			}
		}
	}
	.form-footer {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
		height: 44px;
		background: #f9f9f9;
		border-radius: 0px 0px 16px 16px;
		font-size: 12px;
		color: #60636f;
		cursor: pointer;
		.icon {
			display: flex;
			height: 100%;
			width: 28px;
			justify-content: center;
			align-items: center;
			img {
				height: 16px;
				width: 16px;
			}
		}
		em {
			font-style: normal;
			color: #f32a35;
		}
	}
}
