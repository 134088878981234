:global {
	.zmsp-popup {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 100;
		display: flex;
		justify-content: center;
		align-items: center;
		&.point {
			pointer-events: none;
		}
	}

	.zmsp-popup-mask {
		position: absolute;
		width: 100%;
		height: 100%;
		background-color: rgba(0, 0, 0, 0.7);
	}

	.zmsp-popup-inner {
		box-sizing: border-box;
		color: #333;
		font-size: 18px;
	}
}
