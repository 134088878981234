@import '../components/dialogs/popup/styles.scss';
@import '../components/dialogs/toast/styles.scss';
@import './form.scss';
$global-font: 'PingFang SC', 'Microsoft YaHei', '思源黑体 CN Normal', 'Helvetica Neue', Helvetica, Arial, 'Hiragino Sans GB', 'Heiti SC';

html,
body {
	width: 100%;
	height: 100%;
	margin: 0;
	padding: 0;
	overflow: hidden;
	font-family: $global-font;
	user-select: none;
}

* {
	margin: 0;
	padding: 0;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}

*:focus {
	outline: none;
}

a {
	text-decoration: none;
}

:global {
	#app {
		display: flex;
		justify-content: center;
		align-items: center;
		height: 100%;
	}

	input {
		font-family: $global-font;
		border: none;
		outline: none;
	}
	button {
		background: transparent;
		border: none;
	}
	li {
		list-style: none;
	}

	.geetest_panelshowslide {
		width: 250px !important;
		height: 250px !important;
	}
	.container {
		position: relative;
		width: 340px;
		height: 470px;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: space-between;
		background: url(https://web-data.zmlearn.com/image/p1xMi1mQXGtzPP9jWMkmTJ/img_bg%402x.png) no-repeat;
		background-size: cover;
		.close-btn {
			width: 16px;
			height: 16px;
			background-image: url(https://web-data.zmlearn.com/image/raXE2Wjzk4AtNTPm4EikcY/log_icon_close_grey%402x.png);
			background-size: cover;
			position: absolute;
			right: 15px;
			top: 15px;
			cursor: pointer;
		}
		.logo {
			height: 42px;
			text-align: center;
			margin-top: 78px;
			&.sm {
				margin-top: 29px;
			}
			img {
				height: 100%;
			}
		}
		&-v2 {
			.bar {

			}
		}
	}
	.container-v2 {
		width: 100%;
		height: 100%;
	}
}
