:global {
	.zmsp-toast {
		padding: 10px 30px;
		overflow: hidden;
		background: rgba(0, 0, 0, 0.7);
		border-radius: 4px;
		text-align: center;
		font-size: 16px;
		color: #ffffff;
		max-width: 360px;
		min-width: 164px;
		box-sizing: border-box;
		word-break: break-word;
		& > p {
			margin: 0;
			padding: 0;
		}
	}
}
